<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-for="breadcrumb in breadcrumbs"
              :to="breadcrumb.to"
              :key="breadcrumb.name"
            >{{ breadcrumb.name }}</b-breadcrumb-item>

            <b-breadcrumb-item active>編輯角色權限</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{ organizationName }}編輯角色權限
              </h4>

              <role-form :role="role" :is-read-only="true" ref="form"></role-form>
            </div>

            <div class="row">
              <div class="col-12 col-xl-10 m-3">
                <div class="row mb-2">
                  <div class="col-lg-4 col-12 px-0" style="font-size:1.2rem;">
                    <b-form-checkbox
                      v-model="onlyShowChecked"
                    >
                      只顯示有權限的區塊
                    </b-form-checkbox>
                  </div>
                </div>
                <hr style="border-top: 1px solid #CCCCCC;">
                <div v-for="key in Object.keys(groupedPermissions).sort()" :key="key">
                  <template v-if="!onlyShowChecked || hasCheckedPermission(key)">
                    <div class="row">
                      <div class="col-lg-4 col-12 px-0" style="font-size:1.2rem;">
                        <b-form-checkbox
                          :key="key"
                          :value="key"
                          v-model="checkedAll"
                          @change="handleAllCheckbox(key)"
                        >
                          {{ key }}
                        </b-form-checkbox>
                      </div>
                      <div class="col-lg-8 col-12 d-flex flex-wrap">
                        <div class="col-12" :key="sub_category" v-for="(sub_category, i) in Object.keys(groupedPermissions[key]).sort()">
                          <div class="row">
                            <div class="col-12 px-0" style="font-size:1.1rem;" v-if="sub_category != 'null'">
                              <b-form-checkbox
                                :key="sub_category"
                                :value="`${key}_${sub_category}`"
                                v-model="checkedSubAll"
                                @change="handleAllSubCheckbox(key, sub_category)"
                              >
                                {{ sub_category }}
                              </b-form-checkbox>
                            </div>
                            <b-form-checkbox
                              v-for="permission in groupedPermissions[key][sub_category]"
                              :key="permission.key"
                              :value="permission.id"
                              v-model="checkedPermissions"
                              @change="triggerAllCheckbox($event, permission)"
                            >
                              {{ permission.name }}
                            </b-form-checkbox>
                          </div>
                          <hr v-if="Object.keys(groupedPermissions[key]).length > 1 &&  i < Object.keys(groupedPermissions[key]).length - 1" style="border-top: 1px solid #CCCCCC;">
                        </div>
                      </div>
                    </div>
                    <hr style="border-top: 1px solid #CCCCCC;">
                  </template>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                :to="getRoleListLink()"
                variant="outline-danger"
              >返回
              </b-button>
              <b-button @click="handleSubmit" variant="success">儲存</b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import https from "../../../apis/https"
import roleApi from "../../../apis/role";
import RoleForm from "./RoleForm"
import orgNameMixin from "@/mixins/organizationName"
import _ from "lodash"

export default {
  mixins: [orgNameMixin],
  components: { RoleForm },
  data() {
    return {
      showLoading: false,
      role: {
        name: null,
        code: null,
        description: null,
      },
      copyFromRole: null,
      permissions: [],
      groupedPermissions: {},
      onlyShowChecked: false,
      checkedAll: [],
      checkedSubAll: [],
      checkedPermissions: [],
      organizationName: this.$store.state.general.organization.name,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    orgId() {
      return this.$route.params.orgId || this.organization.id;
    },
    breadcrumbs() {
      if (this.$route.name == 'OrgRolePermissionEdit') {
        return [
          { to: { name: 'SystemSettingsHome' }, name: '全系統管理' },
          { to: { name: 'OrganizationList' }, name: '組織管理' },
          { to: { name: 'RoleList', params: { orgId: this.orgId } }, name: '角色列表' },
        ]
      }

      return [
        { to: { name: 'RoleList' }, name: '角色列表' },
      ]
    }
  },
  async mounted() {
    await this.fetchRole();
    if (this.$route.query.copy_from) {
      await this.copyPermissionsFromRole(this.$route.query.copy_from);
      this.showCopyMessage();
    }
    await this.fetchPermissions();
    this.groupPermissions();
    this.setCheckedAll();
  },
  methods: {
    async fetchRole() {
      const result = await roleApi.getRole(this.orgId, this.$route.params.role_id);
      this.role = result.data.data;
      this.checkedPermissions = this.role.permissions.map(permission => permission.id)
    },
    async fetchPermissions() {
      const result = await https.get(`admin/permissions`)
      const adminPermissions = ['ORGANIZATION_LIST_VIEW', 'ORGANIZATION_MODIFY', 'ORGANIZATION_DELETE', 'MERCHANT_LIST_VIEW', 'MERCHANT_MODIFY', 'MERCHANT_DELETE'];
      this.permissions = result.data.data.filter(permission => {
        if (adminPermissions.includes(permission.key) && !this.user.is_sys_admin) {
          // 編輯角色全縣，只有系統管理員可以
          return false;
        }
        return true;
      });
    },
    groupPermissions() {
      this.groupedPermissions = this.permissions.reduce((group, permission) => {
        const { category, sub_category } = permission;
        group[category] = group[category] ?? []
        group[category][sub_category]  = group[category][sub_category] ?? []
        group[category][sub_category].push(permission)
        group[category][sub_category] = group[category][sub_category].sort((a, b) => a.order - b.order)
        return group;
      }, {});
    },
    setCheckedAll() {
      Object.entries(this.groupedPermissions).forEach(entry => {
        const [ category, subCategories ]= entry
        Object.entries(subCategories).forEach(subEntry  => {
          const [ sub_category, permissions ] = subEntry
          const doesSelectSubAll = permissions.every(permission => this.checkedPermissions.includes(permission.id))
          if (doesSelectSubAll) {
            this.checkedSubAll.push(this.keyName(category, sub_category))
          }
        })

        const doesSelectAll = Object.keys(this.groupedPermissions[category]).map(name => this.keyName(category, name)).every(sub_category => {
          return this.checkedSubAll.includes(sub_category)
        })
        if (doesSelectAll) {
          this.checkedAll.push(category)
        }
      })
    },
    async copyPermissionsFromRole(roleId) {
      const { data } = await roleApi.getRole(this.orgId, roleId);
      this.copyFromRole = data.data;

      switch (this.$route.query.copy_type) {
        case 'remove_and_copy':
          this.checkedPermissions = this.copyFromRole.permissions.map(permission => permission.id)
          break;
        case 'reserve_and_copy':
          this.checkedPermissions = this.checkedPermissions.concat(this.copyFromRole.permissions.map(permission => permission.id))
          break;
      }
    },
    showCopyMessage() {
      const message = this.$route.query.copy_type === 'remove_and_copy'
        ? `已移除 ${this.role.name} 現有權限，用 ${this.copyFromRole.name} 權限取代`
        : `已繼承 ${this.role.name} 現有權限，並加上 ${this.copyFromRole.name} 權限`;
      this.$swal.fire({
        title: message,
        type: "success",
        confirmButtonText: "確認"
      }).then(() => {
        this.$swal.fire({
          html: `點選儲存後，權限才會生效。<br>若不更新權限，請直接離開此頁面`,
          type: "success",
        })
      });
    },
    hasCheckedPermission(key) {
      return Object.values(this.groupedPermissions[key]).some(sub_category => {
        return sub_category.some(permission => this.checkedPermissions.includes(permission.id))
      })
    },
    keyName(category, sub_category) {
      return `${category}_${sub_category}`
    },
    handleAllCheckbox(category) {
      const permissionIds = _.flatten(Object.values(this.groupedPermissions[category]).map(sub_category => sub_category.map(permission => permission.id)))
      const doesSelectAll = this.checkedAll.includes(category)

      if (doesSelectAll) {
        permissionIds.forEach(permissionId => this.checkedPermissions.push(permissionId))
        this.checkedSubAll.push(...Object.keys(this.groupedPermissions[category]).map(name => this.keyName(category, name)))
      } else {
        this.checkedPermissions = this.checkedPermissions.filter(checkedPermission => {
          return ! permissionIds.includes(checkedPermission)
        })
        this.checkedSubAll = this.checkedSubAll.filter(v => !Object.keys(this.groupedPermissions[category]).map(name => this.keyName(category, name)).includes(v))
      }
    },
    handleAllSubCheckbox(category, sub_category) {
      const permissionIds = this.groupedPermissions[category][sub_category].map(permission => permission.id)
      const doesSelectAll = this.checkedSubAll.includes(this.keyName(category, sub_category))

      if (doesSelectAll) {
        permissionIds.forEach(permissionId => this.checkedPermissions.push(permissionId))
      } else {
        this.checkedPermissions = this.checkedPermissions.filter(checkedPermission => {
          return ! permissionIds.includes(checkedPermission)
        })
      }
    },
    triggerAllCheckbox(checkedIds, permission) {
      const doesChecked = checkedIds.includes(permission.id)

      if (doesChecked) {
        const permissionIds = this.groupedPermissions[permission.category][permission.sub_category].map(permission => permission.id)
        const doesSelectAll = permissionIds.every(permissionId => {
          return checkedIds.includes(permissionId)
        })
        if (doesSelectAll) {
          this.checkedAll.push(permission.category)
          this.checkedSubAll.push(this.keyName(permission.category, permission.sub_category))
        }
      } else {
        const index = this.checkedAll.indexOf(permission.category)
        if (index > -1) {
          this.checkedAll.splice(index, 1)
        }
        const sub_index = this.checkedSubAll.indexOf(this.keyName(permission.category, permission.sub_category))
        if (sub_index > -1) {
          this.checkedSubAll.splice(index, 1)
        }
      }
    },
    getRoleListLink() {
      if (this.$route.name == 'OrgRolePermissionEdit') {
        return { name: 'OrgRoleList', params: { orgId: this.orgId }, }
      }
      return { name: 'RoleList' }
    },
    async handleSubmit() {
      try {
        let response = await roleApi.syncPermissions(this.orgId, this.role, { permission_ids: this.checkedPermissions });
        if (response.status && response.status === 200) {
          this.$swal.fire({
            title: "編輯權限成功",
            type: "success",
          });

          // 更新一下當前用戶的權限讓頁面選單更新
          this.$store.dispatch("auth/fetchUser");
          this.$router.push(this.getRoleListLink());
        }
      } catch (error) {
        this.$swal.fire({
          title: error.response.data?.message || "編輯權限失敗",
          type: "error",
        });
      }
    },
  }
};
</script>

<style scoped lang="scss">
.custom-checkbox {
  margin-right: 1rem;
}
</style>
